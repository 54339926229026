import React from "react"
import "./styles.scss"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row footer-center">
        <div className="col-md-6 text-center">
          <p>Precision PDR 2020</p>
        </div>
        <div className="col-md-6 text-center">
          <ul className="nav footer-nav justify-content-center">
            <li className="nav-item">
              <a className="nav-link" href="/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/terms-of-use">
                Terms of Use
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row footer-tag">
        <div className="col-12 text-center">
          <span>Site by </span>
          <a
            href="https://www.dssdigital.net/?utm_source=website&utm_medium=tag&utm_campaign=precision_pdr"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSS Digital
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
