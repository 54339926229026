import React from 'react';
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"

import logo from "../../images/logo.png"
import "./styles.scss"

class Navi extends React.Component {
    render() {
        const { location } = this.props
        return (
            <section className="container">
                <Navbar expand="lg">
                    <a className="navbar-brand" href="/">
                        <img src={logo} className="navbar-image" alt="Precision PDR" />
                    </a>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav nav-fill">
                            <li
                                className={
                                location.pathname === '/' ? 'nav-item active' : 'nav-item'
                                }
                            >
                                <Link to="/" className="nav-link">
                                Home
                                </Link>
                            </li>
                            <li
                                className={
                                location.pathname === '/about' ? 'nav-item active' : 'nav-item'
                                }
                            >
                                <Link to="/about" className="nav-link">
                                About
                                </Link>
                            </li>
                            <li
                                className={
                                location.pathname === '/gallery' ? 'nav-item active' : 'nav-item'
                                }
                            >
                                <Link to="/gallery" className="nav-link">
                                Gallery
                                </Link>
                            </li>
                            <li
                                className={
                                location.pathname === '/community-support' ? 'nav-item active' : 'nav-item'
                                }
                            >
                                <Link to="/community-support" className="nav-link">
                                Community Support
                                </Link>
                            </li>
                            <li
                                className={
                                location.pathname === '/contact' ? 'nav-item active' : 'nav-item'
                                }
                            >
                                <Link to="/contact" className="nav-link">
                                Contact
                                </Link>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </Navbar>
            </section>
        )
    }
}

export default Navi;