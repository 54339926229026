import React from "react"
import Top from "../Top"
import Navi from "../Navi"
import Footer from "../Footer"

import "./styles.scss"

class Layout extends React.Component {
  render() {
    const { children, location } = this.props
    return (
      <>
        <Top />
        <Navi location={location} />
          {children}
        <Footer />
      </>
    )
  }
}

export default Layout
