import React from 'react';

import Icon from "../Icon"
import "./styles.scss"

class Top extends React.Component {
    render() {
        return (
            <section className="row top">
                <div className="container text-right align-self-center desktop">
                    <p className="top-text">6/35 Cumberland Avenue South Nowra - 0413 600 097</p>
                </div>
                <div className="container text-right align-self-center mobile">
                    <a className="btn btn-sm btn-primary" role="button" href="https://www.google.com/maps/place/Precision+Dent+Removals/@-34.9152697,150.5854955,198m/data=!3m1!1e3!4m16!1m10!4m9!1m1!4e2!1m6!1m2!1s0x6b14809cd2fa558b:0xb767e786a9f9b571!2sprecision+pdr+nowra!2m2!1d150.585723!2d-34.915447!3m4!1s0x6b14809cd2fa558b:0xb767e786a9f9b571!8m2!3d-34.915447!4d150.585723">Location <Icon name="location-arrow" /></a>
                    <a className="btn btn-sm btn-primary" role="button" href="tel:0413600097">Call <Icon name="phone" /></a>
                </div>
            </section>
        )
    }
}

export default Top;